import React from "react"

function StatHeadline({ title, summary, stat }) {
  return (
    <div className="container customers">
      <div className="row">
        <div className="nine columns">
          <h2>{title}</h2>
          <p>{summary}</p>
        </div>
        <div className="three columns">
          <div className="data">{stat}</div>
        </div>
      </div>
    </div>
  )
}

export default StatHeadline
