import React from "react"

function WorkersExample({ title, description, imageSource }) {
  return (
    <div className="row">
      <div className="five columns">
        <img src={imageSource} alt={title} />
      </div>
      <div className="seven columns">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default WorkersExample
