import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import quoteImage from "../images/spot-workers-quote.png"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SideBySide from "../components/SideBySide"
import Ready from "../components/Ready"
import WorkersExample from "../components/WorkersExample"
import StatHeadline from "../components/StatHeadline"
import Quote from "../components/Quote"
import WorkersHero from "../components/WorkersHero"

export const quote = {
  citation: "Deputy Director, Public Works",
  quote:
    "Spot Workers has improved the efficiency of our operations tenfold. We’re completely dependent on it now.",
  imageSource: quoteImage,
}

export const examplesHeader = "How is Spot Workers being used?"

function WorkersPage() {
  const data = useStaticQuery(graphql`
    query WorkersQuery {
      allWorkerHighlightsYaml {
        nodes {
          id
          last
          title
          description
          direction
          imageSource {
            publicURL
          }
        }
      }
      allWorkerExamplesYaml {
        nodes {
          id
          description
          title
          imageSource {
            publicURL
          }
        }
      }
    }
  `)

  const title = "Spot Workers"
  const headline = "Do more with the workers you have"
  const subheadline =
    "Workers go straight into the field, manage tasks on a phone, and respond to new requests in real time. Reduced paperwork and easy access to information improves worker productivity."

  const highlights = data.allWorkerHighlightsYaml.nodes
  const examples = data.allWorkerExamplesYaml.nodes

  return (
    <Layout>
      <SEO title={title} />
      <WorkersHero />
      <StatHeadline title={headline} summary={subheadline} stat="5x" />
      <Quote citation={quote.citation} imageSource={quote.imageSource}>
        {quote.quote}
      </Quote>
      {highlights.map(highlight => (
        <SideBySide
          key={highlight.id}
          title={highlight.title}
          description={highlight.description}
          imageSource={highlight.imageSource.publicURL}
          direction={highlight.direction}
          last={highlight.last}
        />
      ))}
      <div className="container examples">
        <div className="row header">
          <h2>{examplesHeader}</h2>
        </div>
        {examples.map(example => (
          <WorkersExample
            key={example.id}
            title={example.title}
            description={example.description}
            imageSource={example.imageSource.publicURL}
          />
        ))}
      </div>
      <Ready file="/spot-workers.pdf" title="Download Spot Workers (PDF)" />
    </Layout>
  )
}

export default WorkersPage
